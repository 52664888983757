<template>
  <div class="articleDetail">
    <div class="header">
      <div class="center">
        <img src="@/assets/login-logo.png" alt="" />
        <router-link :to="{path: '/home/ArticleContainer', query: {type: this.$route.query.type}}" class="header-right">返回{{ getName }} <i class="el-icon-arrow-right"></i> </router-link>
      </div>
    </div>
    <div class="articleDetail-top">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{path: '/'}">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{path: '/home/ArticleContainer', query: {type: this.$route.query.type}}">
          {{ getName }}
        </el-breadcrumb-item>
        <el-breadcrumb-item>文章详情</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="articleDetail-main">
      <div class="content">
        <p class="title">{{ detail.title }}</p>
        <p class="time">发布时间：{{ detail.updateTime }}</p>
        <div v-html="detail.contend" class="article"></div>
        <div v-if="$route.query.type === 'deal' && detail.id">
          <div class="articleDetail-main-field">
            <h2>项目信息：</h2>
            <div class="msg">
              <div>项目名称：{{ detail.orderName }}</div>
              <div>项目发布方：{{ detail.publishName }}</div>
              <!-- <div>发布时间：{{ detail.addTime }}</div> -->
              <div>地址：{{ detail.publishAddress }}</div>
              <div>联系方式：{{ detail.publishContactsPhone }}</div>
              <div>联系人：{{ detail.publishContacts }}</div>
            </div>
          </div>
          <div class="articleDetail-main-field">
            <h2>成交信息：</h2>
            <div class="msg">
              <div>成交方：{{ detail.dealName }}</div>
              <div>成交金额：{{ detail.orderPriceStr | formatPrice }}</div>
              <!-- <div>成交时间：{{ detail.dealTime }}</div> -->
              <div>地址：{{ detail.dealAddress }}</div>
              <div>联系方式：{{ detail.dealContactsPhone }}</div>
              <div>联系人：{{ detail.dealContacts }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {getNoticeById} from '../api';
export default {
  data() {
    return {
      detail: {},
      titleMsg: {}
    };
  },
  created() {
    this.getDetail();
  },
  computed: {
    getName() {
      switch (this.$route.query.type) {
        case 'deal':
          return '成交公告';
        case 'news':
          return '新闻动态';
        case 'system':
          return '系统公告';
        default:
          '';
          break;
      }
    }
  },
  methods: {
    async getDetail() {
      let res = await getNoticeById({id: this.$route.query.articleId});
      if (res.errno == 0) {
        this.detail = res.data;
      }
    }
  }
};
</script>
<style lang="less" scoped>
.articleDetail {
  margin-bottom: 14px;
  box-sizing: border-box;
  .header {
    background-color: #fff;
    height: 87px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    .center {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 1200px;
    }

    img {
      height: 50px;
    }

    .header-right {
      color: #a7b1c1;
      margin-left: auto;
      font-size: 15px;
      &:hover {
        color: #188aff;
      }
    }
  }
  &-top {
    display: flex;
    align-items: center;
    height: 45px;
    font-size: 16px;
    width: 1200px;
    margin: 0 auto;
  }
  &-main {
    padding: 55px 35px;
    display: flex;
    justify-content: center;
    background-color: #fff;
    min-height: 700px;
    .content {
      width: 1000px;
    }
    .title {
      text-align: center;
      font-size: 30px;
      font-weight: 600;
    }
    .time {
      color: #888;
      text-align: center;
      padding: 18px 0;
    }
    .article {
      color: #333;
      font-size: 16px;
      padding: 25px 0;
      border-top: 1px solid #eeeeef;
    }
    &-field {
      .msg {
        padding: 15px 0 15px 40px;
        font-size: 16px;
      }
    }
  }

  h3 {
    font-size: 37px;
  }
  &-title {
    margin: 23px 0 32px 0;
  }
  &-main {
    line-height: 30px;
  }
}
</style>
