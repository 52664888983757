var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "articleDetail" }, [
    _c("div", { staticClass: "header" }, [
      _c(
        "div",
        { staticClass: "center" },
        [
          _c("img", {
            attrs: { src: require("@/assets/login-logo.png"), alt: "" },
          }),
          _c(
            "router-link",
            {
              staticClass: "header-right",
              attrs: {
                to: {
                  path: "/home/ArticleContainer",
                  query: { type: this.$route.query.type },
                },
              },
            },
            [
              _vm._v("返回" + _vm._s(_vm.getName) + " "),
              _c("i", { staticClass: "el-icon-arrow-right" }),
            ]
          ),
        ],
        1
      ),
    ]),
    _c(
      "div",
      { staticClass: "articleDetail-top" },
      [
        _c(
          "el-breadcrumb",
          { attrs: { "separator-class": "el-icon-arrow-right" } },
          [
            _c("el-breadcrumb-item", { attrs: { to: { path: "/" } } }, [
              _vm._v("首页"),
            ]),
            _c(
              "el-breadcrumb-item",
              {
                attrs: {
                  to: {
                    path: "/home/ArticleContainer",
                    query: { type: this.$route.query.type },
                  },
                },
              },
              [_vm._v(" " + _vm._s(_vm.getName) + " ")]
            ),
            _c("el-breadcrumb-item", [_vm._v("文章详情")]),
          ],
          1
        ),
      ],
      1
    ),
    _c("div", { staticClass: "articleDetail-main" }, [
      _c("div", { staticClass: "content" }, [
        _c("p", { staticClass: "title" }, [_vm._v(_vm._s(_vm.detail.title))]),
        _c("p", { staticClass: "time" }, [
          _vm._v("发布时间：" + _vm._s(_vm.detail.updateTime)),
        ]),
        _c("div", {
          staticClass: "article",
          domProps: { innerHTML: _vm._s(_vm.detail.contend) },
        }),
        _vm.$route.query.type === "deal" && _vm.detail.id
          ? _c("div", [
              _c("div", { staticClass: "articleDetail-main-field" }, [
                _c("h2", [_vm._v("项目信息：")]),
                _c("div", { staticClass: "msg" }, [
                  _c("div", [
                    _vm._v("项目名称：" + _vm._s(_vm.detail.orderName)),
                  ]),
                  _c("div", [
                    _vm._v("项目发布方：" + _vm._s(_vm.detail.publishName)),
                  ]),
                  _c("div", [
                    _vm._v("地址：" + _vm._s(_vm.detail.publishAddress)),
                  ]),
                  _c("div", [
                    _vm._v(
                      "联系方式：" + _vm._s(_vm.detail.publishContactsPhone)
                    ),
                  ]),
                  _c("div", [
                    _vm._v("联系人：" + _vm._s(_vm.detail.publishContacts)),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "articleDetail-main-field" }, [
                _c("h2", [_vm._v("成交信息：")]),
                _c("div", { staticClass: "msg" }, [
                  _c("div", [_vm._v("成交方：" + _vm._s(_vm.detail.dealName))]),
                  _c("div", [
                    _vm._v(
                      "成交金额：" +
                        _vm._s(_vm._f("formatPrice")(_vm.detail.orderPriceStr))
                    ),
                  ]),
                  _c("div", [
                    _vm._v("地址：" + _vm._s(_vm.detail.dealAddress)),
                  ]),
                  _c("div", [
                    _vm._v("联系方式：" + _vm._s(_vm.detail.dealContactsPhone)),
                  ]),
                  _c("div", [
                    _vm._v("联系人：" + _vm._s(_vm.detail.dealContacts)),
                  ]),
                ]),
              ]),
            ])
          : _vm._e(),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }